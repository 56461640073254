.aboutContainer {
    padding: 60px;
    padding-top: 20px;
    font-size: 18px;
    text-align: left;
    padding-right: 100px;
    padding-left: 100px;
}

.aboutHeader {
    padding-bottom:20px;
    text-align: center;
    font-family: 'Crimson Text', serif!important;
}

#firstPara {
    border-left: 5px solid #8395a7;
    padding-left: 10px;
    border-color: rgb(149, 149, 151)!important;
}

.aboutPara {
    padding-top: 0px;
    padding: 10px;
}

@media screen and (max-width: 600px) {
    .aboutContainer {
        padding-top: 30px!important;
    }
}