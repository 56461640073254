.navbar-custom {
    background-color: #f5f3f3!important;
}
 
 .navbar-brand {
     font-family: 'Crimson Text', serif!important;
     letter-spacing: 2px;
     padding-left: 3%;
     color: black!important;
 }
 
 .navbar-nav .nav-link {
     font-family: 'Crimson Text', serif!important;
     font-weight: 500;
     font-size: 18px;
     color: black!important;
 }
 
 .navbar-nav .nav-link:hover {
    background-color: rgb(183, 183, 194)!important;
    border-color:rgb(183, 183, 194)!important;
    color: white!important;
    border-radius: 300px;
 }
 
 .navbar-nav{
     padding-right:35px;
 }

 #responsive-navbar-nav {
     text-align: center;
 }