.projectButtonContainer {
    text-align: center;
    padding-top: 20px!important;
    padding-bottom: 10px!important;
}

.projectButton {
    background-color: #f5f3f3!important;
    border-color:#f5f3f3!important;
    border-radius: 300px;
    color: black;
    text-align: center;
    margin-right:10px;
    margin-left: 10px;
    font-family: 'Crimson Text', serif!important;
    font-size: 18px;
}

.projectButton:hover{
    background-color: rgb(183, 183, 194)!important;
    border-color:rgb(183, 183, 194)!important;
    color: white!important;
}

.projectSection {
    padding-top: 10px!important;
    margin-bottom: 50px;
    text-align: left;
}

.projectSection .imgBox{
    position: relative;
    overflow: hidden;
}

.projectImg {
    transition: transform 1s;
}

.projectImg:hover {
    transform: scale(1.2);
}

.noImg {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.projectTitle{ 
    font-family: 'Crimson Text', serif!important;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.projectDesc{ 
    padding-bottom: 10px;
}

.projectTech{ 
    font-weight: 400;
    font-size: 16px!important;
    padding-bottom: 10px;
}

#icon {
    padding-right: 2px!important;
}

.projectBtnGit {
    background-color: rgb(189, 196, 218)!important;
    border-color: rgb(190, 190, 204)!important;
    padding-bottom: 6px!important;
    padding-top: 6px!important;
    padding-right: 12px;
    padding-left: 12px;
    color: black;
    font-size: 16px;
    font-weight: 500;
    border-radius: 300px;
    margin-right: 10px;
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none!important;
    color: black;
 }

.projectBtnWeb {
    padding-bottom: 6px!important;
    padding-top: 6px!important;
    padding-right: 12px;
    padding-left: 12px;
    background-color: rgb(209, 221, 214)!important;
    border-color: rgb(209, 221, 214)!important;
    color: black;
    font-size: 16px;
    font-weight: 500;
    border-radius: 300px;
    margin-right: 20px;
    font-style: normal!important;
}

@media screen and (max-width: 600px) {
    .projectContainer {
        padding-top: 0px!important;
        padding-left: 0px!important;
    }
}