.homeContainer {
   padding-top: 80px;
   text-align: left;
}

/***************** Description Section ********************/
#landingGreeting {
   font-size: 25px;
}

 #landingHeader {
   font-family: 'Crimson Text', serif!important;
   font-size:70px!important;
   color: rgb(149, 149, 151)!important;
   letter-spacing: 1px;
}

#landingTag{
   font-size:60px!important;
   color:  rgb(41, 42, 43)!important;
   padding-bottom: 15px;
}

#landingDesc {
   font-size: 18px!important;
   padding-bottom: 5px;
}

.landingBtn {
   background-color: rgb(205, 228, 240)!important;
   border-color: rgb(205, 228, 240)!important;
   color: black;
   font-size: 18px;
   font-weight: 500;
   padding:10px;
   padding-right: 15px;
   padding-left: 15px;
   border-radius: 300px;
}

.landingBtn:hover {
   text-decoration: none;
   color:white;
}

 @media screen and (max-width: 600px) {
   .container {
      padding: 80px 0!important;
      padding-left: 20px!important;
    }

   #landingGreeting {
      font-size: 30px;
   }

   #landingHeader {
     font-size: 65px!important;
   }

   #landingTag{
      font-size: 50px!important;
      padding-bottom: 10px;
   }

   #landingDesc {
      font-size: 15px!important;
      padding-right: 40px;
   }

   .carousel {
      display: none!important;
   }
 }

/***************** Carousel Section ********************/
.carousel {
   width: 600px;
   height: 450px!important;
}

.carousel-item {
   height: 400px!important;
   width: 600px!important;
 }

.carousel-item img {
   height: 500px!important;
}
