.contactContainer {
    padding: 40px!important;
    padding-top: 20px;
    font-size: 18px;
    text-align: left;
    padding-right: 100px!important;
    padding-left: 100px!important;
}

#firstPara {
    padding-top: 0px!important;
    padding-bottom: 0px;
    margin-bottom: 25px;
}
.contactSection {
    padding-bottom: 20px;
    padding: 20px!important;
}

.contactHeader {
    font-family: 'Crimson Text', serif!important;
    padding-bottom: 20px;
    text-align: center;
}

.contactBtn {
    background-color: transparent;
    padding: 0px;
}

.contactBtn:hover{
    color: yellow!important;
}

.social {
    display: block;
    padding: 3px;
    font-size: 18px;
   
}

a:hover {
    outline: none;
    text-decoration: none;
  
}

#code {
    color: black;
    font-size: 23px;
}

#code:hover {
    font-size: 28px;
    color:rgb(149, 149, 151)!important;
}

.fa-envelope {
    color: rgb(187, 0, 27)!important;
    font-size: 22px;
}
.fa-envelope:hover {
    font-size: 28px;
    color: rgb(149, 149, 151)!important;
}

.fa-linkedin {
    font-size: 25px;
    color: rgb(40,103,178);
}

.fa-linkedin:hover {
    font-size: 30px;
    color: rgb(149, 149, 151)!important;
}

label {
      margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .contactContainer {
        padding-top: 30px!important;
        padding-right: 20px!important;
        padding-left: 20px!important;
    }
}